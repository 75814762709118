import { Form } from "@remix-run/react";
import { json } from "@remix-run/node";

import type { ActionFunction } from "@remix-run/node";

import { login } from "~/utils/login.server";
import { createUserSession } from "~/utils/session.server";
import NarrowPage from "~/components/NarrowPage";

const badRequest = (data: { formError: string }) => json(data, { status: 400 });

export const action: ActionFunction = async ({ request }) => {
  const form = await request.formData();
  const username = form.get("username");
  const password = form.get("password");

  if (typeof username !== "string" || typeof password !== "string")
    return badRequest({ formError: "Missing username/password" });

  const user = await login({ username, password });
  if (!user) {
    return badRequest({
      formError: "Username/Password combination is incorrect",
    });
  }

  return createUserSession(user.id, "/");
};

export default function Login() {
  return (
    <NarrowPage>
      <h1>login</h1>
      <Form method="post">
        <div>
          <label htmlFor="username-input">username</label>
          <input type="text" id="username-input" name="username" />
        </div>
        <div>
          <label htmlFor="password-input">password</label>
          <input type="password" id="password-input" name="password" />
        </div>
        <button type="submit">Submit</button>
      </Form>
    </NarrowPage>
  );
}
